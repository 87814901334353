@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

body {
    font-family: "Roboto Light", Helvetica, Arial, sans-serif;
}

html, body, #root {
    margin: 0;
    padding: 0;
}

// helpers

.fillContainer {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
}

.hidden {
    display: none !important;
}

.left {
    float: left;
}

.right {
    float: right;
}

.noPadding {
    padding: 0 !important;
}

.noMargin {
    margin: 0 !important;
}

.centeredText {
    text-align: center;
}

.actionIcon {
    cursor: pointer;
}

// MUI overrides
[class^="MuiDivider"] {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

// OpenLayers overrides
.layer-switcher, .layer-switcher:hover {
    background-color: #aaa;
    padding: 1px;

    & .panel {
        border-color: #aaa;
        border-width: 2px;
    }
}
